/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useEffect } from 'react'

import H1 from 'elements/H1'
import Text from 'elements/Text'
import Image from 'elements/Image'
import { stripHtml, removeNonAscii } from 'services/util'

import './index.scss'

const TopBlog = ({ image, title, blogPath, description, childContentfulBlogDescriptionRichTextNode }) => {
  useEffect(()=> {
    const domBlogs = document.querySelectorAll('.topblog-contents')
    for(let i = 0; i < domBlogs.length; i++) {
      let paras = domBlogs[i].getElementsByTagName('H2');
      while(paras[0]) paras[0].parentNode.removeChild(paras[0]);
      domBlogs[i].innerText = removeNonAscii(stripHtml(domBlogs[i].innerHTML))
    } 
  }, [title])
  return (
    <div className="topblog">
        <Image src={ image?.file?.url } ratio="0.7" className="topblog-image"/>
        <Link to={blogPath}>
        <div className="text-wrapper">
          <H1 size="large-mini-minus" className="topblog-title">{title}</H1>
          <div className="topblog-contents">
            {documentToReactComponents(childContentfulBlogDescriptionRichTextNode?.json)}
          </div>
        </div>
        </Link>
    </div>
  )
}

export default TopBlog