/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState, useEffect } from 'react'

import TopBlog from './TopBlog'
import './index.scss'

const SecondBlock = ({ blogs }) => {
  const [addNew, setAddNew] = useState(false)

  const getSize = () => {
    if(window.innerWidth >= 1170) {
      setAddNew(false)
    }
    if(window.innerWidth < 1170){
      setAddNew(true)
    }
  }

  useEffect(() => {
    getSize()
    if(window) {
      window.addEventListener('resize', getSize)
    }
    return () => {
      window.removeEventListener('resize', getSize)
    }
  }, [])

  return (
    <div id="blog-second-block" className="blog-second-block">
      <div className="blog-second-board">
        <TopBlog {...blogs[0]} />
        <TopBlog {...blogs[1]} />
        <TopBlog {...blogs[2]} />
        { addNew &&
          <TopBlog {...blogs[3]} />
        } 
      </div>
    </div>
  )
}

export default SecondBlock